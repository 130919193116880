import { create, StoreApi, UseBoundStore } from 'zustand';

interface IOrderType {
  orderType: string;
  setOrderType: (orderType: string) => void;
}

export const useOrderType: UseBoundStore<StoreApi<IOrderType>> = create((set) => ({
  orderType: 'all',
  setOrderType: (orderType: string) => set({ orderType }),
}));
