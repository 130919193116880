import { useRequest } from 'ahooks';

import { formatDecimalPlaces, fromBigNumberPrice } from '@/lib/format';

import { getLiquidationPrice } from '@/api';

export const useLiquidationPrice = () => {
  const { data, run, mutate, cancel } = useRequest(
    (isLong: boolean, size: string, collateral: string, entryPrice: string) =>
      getLiquidationPrice(isLong, size, collateral, entryPrice),
    {
      debounceWait: 500,
      manual: true,
    },
  );

  return {
    data: data ? formatDecimalPlaces(fromBigNumberPrice(data)) : '0',
    run,
    cancel,
    mutate,
  };
};
