import useSWR from 'swr';

import { useAprStore } from '@/stores';

import { getStakingApy } from '@/api';
import { PoolUrl } from '@/api/url';

export const useStakingApy = () => {
  const { setApr } = useAprStore();

  useSWR(PoolUrl.StakingApy, async () => getStakingApy(), {
    refreshInterval: 50000,
    onSuccess: (data) => {
      setApr(data.data);
    },
  });
};
