import { create, StoreApi, UseBoundStore } from 'zustand';

import { ICexPrice, IPrices } from '@/types/api';

interface IPricesStore {
  prices?: IPrices;
  cexPrices?: ICexPrice;
  setPrices: (prices?: IPrices) => void;
  setCexPrices: (cexPrices?: ICexPrice) => void;
}

export const usePricesStore: UseBoundStore<StoreApi<IPricesStore>> = create((set) => ({
  prices: undefined,
  cexPrices: undefined,
  setPrices: (prices?: IPrices) => set({ prices }),
  setCexPrices: (cexPrices?: ICexPrice) => set({ cexPrices }),
}));
