import useSWR from 'swr';

import { formatDealHistory } from '@/lib/utils';

import { getDealHistory } from '@/api';
import { OrderUrl } from '@/api/url';

export const useDealHistory = (symbol: string) => {
  const { data } = useSWR(
    symbol ? `${OrderUrl.Trade}-${symbol}` : null,
    async () => getDealHistory(symbol),
    {
      refreshInterval: 5000,
    },
  );
  return {
    deals: formatDealHistory(data),
  };
};
