import useSWR from 'swr';

import { formatCompactNumber } from '@/lib/format';

import { getVolumeTotal } from '@/api';
import { DashboardUrl } from '@/api/url';

export const useVolumeTotal = () => {
  const { data } = useSWR(DashboardUrl.VolumeTotal, async () => getVolumeTotal(), {
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      if (retryCount >= 2) return;
      setTimeout(() => revalidate({ retryCount: retryCount }), 10000);
    },
  });
  return {
    volumeTotal: formatCompactNumber(data) || 0,
  };
};
