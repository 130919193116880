import { create, StoreApi, UseBoundStore } from 'zustand';

import { ALL_TOKENS } from '@/consts';

import { TApproveKey } from '@/types';

interface IPoolStore {
  poolToken: TApproveKey;
  setPoolToken: (poolToken: TApproveKey) => void;
  tab: number;
  setTab: (tab: number) => void;
}

export const usePoolStore: UseBoundStore<StoreApi<IPoolStore>> = create((set) => ({
  poolToken: Object.keys(ALL_TOKENS)[0] as TApproveKey,
  setPoolToken: (poolToken: TApproveKey) => set({ poolToken }),
  tab: 1,
  setTab: (tab: number) => set({ tab }),
}));
