import useSWR from 'swr';

import { formatBidsAndAsks } from '@/lib/utils';

import { getBidsAndAsks } from '@/api';
import { OrderUrl } from '@/api/url';

export const useBidsAndAsks = (symbol: string, depth = 0.01) => {
  const { data } = useSWR(
    `${OrderUrl.Order}-${symbol}-${depth}`,
    async () => getBidsAndAsks(symbol, depth),
    {
      refreshInterval: 3000,
    },
  );

  return {
    data: formatBidsAndAsks(data as any),
    originalData: data,
    price: data?.price ? Number.parseFloat(data?.price).toFixed(2) : '0',
  };
};
