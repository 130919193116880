import { create, StoreApi, UseBoundStore } from 'zustand';

interface IInviteStore {
  isCreateInviteCode: boolean;
  isBindInviteCode: boolean;
  setIsCreateInviteCode: (a: boolean) => void;
  setIsBindInviteCode: (a: boolean) => void;
}

export const useInviteStore: UseBoundStore<StoreApi<IInviteStore>> = create((set) => ({
  isCreateInviteCode: false,
  setIsCreateInviteCode: (a: boolean) => set(() => ({ isCreateInviteCode: a })),
  isBindInviteCode: false,
  setIsBindInviteCode: (a: boolean) => set(() => ({ isBindInviteCode: a })),
}));
