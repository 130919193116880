import { create, StoreApi, UseBoundStore } from 'zustand';

import { IVaultAmounts } from '@/types';

interface IAmountStore {
  poolAmounts?: IVaultAmounts;
  reservedAmounts?: IVaultAmounts;
  setPoolAmounts: (poolAmounts: IVaultAmounts) => void;
  setReservedAmounts: (reservedAmounts: IVaultAmounts) => void;
}

export const useAmountStore: UseBoundStore<StoreApi<IAmountStore>> = create((set) => ({
  poolAmounts: undefined,
  reservedAmounts: undefined,
  setPoolAmounts: (poolAmounts: IVaultAmounts) => set({ poolAmounts }),
  setReservedAmounts: (reservedAmounts: IVaultAmounts) => set({ reservedAmounts }),
}));
