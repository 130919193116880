import { create, StoreApi, UseBoundStore } from 'zustand';

interface ISelectKey {
  selectKey: string;
  setSelectKey: (selectKey: string) => void;
}

export const useSelectKey: UseBoundStore<StoreApi<ISelectKey>> = create((set) => ({
  selectKey: '',
  setSelectKey: (selectKey: string) => set({ selectKey }),
}));
