import useSWR from 'swr';
import { useAccount } from 'wagmi';

import { getHistoryOrderListNew } from '@/api';
import { OrderUrl } from '@/api/url';

export const useHistoryOrderNew = () => {
  const { address } = useAccount();
  const { data } = useSWR(
    address ? `${OrderUrl.History}-${address}` : null,
    async () => getHistoryOrderListNew(address!),
    {
      refreshInterval: 5000,
    },
  );

  return {
    ...data,
  };
};
