import BigNumber from 'bignumber.js';
import { parseUnits } from 'viem';

import { PRICE_DECIMALS, TOKENS_DECIMALS } from '@/consts';

type BigNumberProp = BigNumber | string | number;

export const fromBigNumberPrice = (price: BigNumberProp, decimal = PRICE_DECIMALS) => {
  if (!price) return new BigNumber(0);

  if (BigNumber.isBigNumber(price)) {
    return price.div(new BigNumber(10).pow(decimal));
  }

  const BN = new BigNumber(price);
  return BN.div(new BigNumber(10).pow(decimal));
};

export const toBigNumberPrice = (price: BigNumberProp, decimal = PRICE_DECIMALS) => {
  if (!price) return new BigNumber(0);

  if (BigNumber.isBigNumber(price)) {
    return price.times(new BigNumber(10).pow(decimal));
  }

  const BN = new BigNumber(price);
  return BN.times(new BigNumber(10).pow(decimal));
};

export const fromBigNumberAmount = (
  amount: BigNumberProp,
  symbol: keyof typeof TOKENS_DECIMALS,
) => {
  if (!amount) return new BigNumber(0);

  if (BigNumber.isBigNumber(amount)) {
    return amount.div(new BigNumber(10).pow(TOKENS_DECIMALS[symbol]));
  }

  const BN = new BigNumber(amount);
  return BN.div(new BigNumber(10).pow(TOKENS_DECIMALS[symbol]));
};

export const parseAmount = (value: string, symbol: keyof typeof TOKENS_DECIMALS) => {
  return parseUnits(value, TOKENS_DECIMALS[symbol]);
};

export const parsePrice = (value: string, decimal = PRICE_DECIMALS) => {
  return parseUnits(value, decimal);
};

export const toBigInt = (value: BigNumber) => {
  const bn = BigNumber.clone();
  bn.config({ DECIMAL_PLACES: 40 });
  return parsePrice(fromBigNumberPrice(new bn(value)).toString());
};

// 1234.5678 => 1234.56
export const formatDecimalPlaces = (
  bn?: BigNumber,
  decimalPlaces = 2,
  type = BigNumber.ROUND_DOWN,
) => {
  if (!bn) return '';
  return bn.decimalPlaces(decimalPlaces, type).toString();
};

export const formatAddress = (address: string) => {
  if (!address) return '';
  const len = address.length;
  return `${address.substring(0, 7)}...${address.substring(len - 5, len)}`;
};

// 100000 => 100K
export const formatCompactNumber = (val?: string | number) => {
  if (!val) return '0';
  const value = Number(val);
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return formatter.format(value);
};

// 100000 => 100,000
export const formatNumber = (val?: string | number, decimalPlaces = 2) => {
  if (!val) return '0';
  const value = Number(val);
  const formatter = Intl.NumberFormat('en-US', { maximumFractionDigits: decimalPlaces });
  return formatter.format(value);
};
