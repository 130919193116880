import useSWR from 'swr';
import { useAccount } from 'wagmi';

import { getEntrustOrderList } from '@/api';
import { OrderUrl } from '@/api/url';

export const useEntrustOrder = () => {
  const { address } = useAccount();
  const orderList = useSWR(
    address ? `${OrderUrl.Entrust}-${address}` : null,
    async () => getEntrustOrderList(address!),
    {
      refreshInterval: 1000,
    },
  );
  return {
    orderList,
  };
};
