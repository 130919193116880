import BigNumber from 'bignumber.js';
import { Address } from 'wagmi';

import { APPROVE_TOKENS, CHART_PERIODS, TRADE_TOKENS } from '@/consts';

export enum TradeType {
  Long = 'long',
  Short = 'short',
}

export enum OrderType {
  Limit = 'limit',
  Market = 'market',
}

export enum StopType {
  TakeProfit = 'TakeProfit',
  StopLoss = 'StopLoss',
}

export enum TradeTokenType {
  U = 'u',
  Token = 'token',
}

export enum OrderRecordType {
  Close = 'close',
  Limit = 'limit',
  Liquidation = 'liquidation',
  Add = 'add',
  Sub = 'sub',
  Cancel = 'cancel',
}

export enum DetailsType {
  Position = 'position',
  Entrust = 'entrust',
  Ended = 'ended',
}

export type TTradeToken = {
  [x: Address]: string;
};

export type TradeTokenKey = keyof typeof TRADE_TOKENS;

export type KLineTimeKey = keyof typeof CHART_PERIODS;

export type StopProfitLossType = {
  isOpen: boolean;
  type: StopType;
  takeProfit: {
    value: string;
    ratio: string;
  };
  stopLoss: {
    value: string;
    ratio: string;
  };
};

export type OpenInterest = {
  longOpenInterest: string;
  shortOpenInterest: string;
  timestamp: number;
};

export type InfoToken = {
  poolAmount?: number;
  usdgAmount?: string;
  minPrice?: BigNumber;
  maxPrice?: BigNumber;
  guaranteedUsd?: string;
  address: Address;
  symbol: string;
  imageUrl: string;
  reservedAmount?: number;
  availableAmount?: BigNumber;
  availableUsd?: BigNumber;
  managedUsd?: string;
};

export type ChartsData = {
  symbol: string;
  color: string;
  value: string;
};

export type TApproveKey = keyof typeof APPROVE_TOKENS;

export type IAllowance = {
  [x in TApproveKey]: number;
};

export type IVaultAmounts = {
  [x in TradeTokenKey]: number;
};

export type Position = {
  size?: number;
  collateral?: number;
  averagePrice?: number;
  entryFundingRate?: number;
  hasRealisedProfit?: number;
  realisedPnl?: number;
  lastIncreasedTime?: number;
  hasProfit?: number;
  delta?: number;
};

export type Token = {
  name: string;
  symbol: string;
  baseSymbol?: string;
  decimals: number;
  address: string;
  coingeckoUrl?: string;
  imageUrl?: string;

  isUsdg?: boolean;
  isNative?: boolean;
  isWrapped?: boolean;
  isShortable?: boolean;
  isStable?: boolean;
  isTempHidden?: boolean;
};

export type TokenInfo = Token & {
  hasMaxAvailableLong?: boolean;
  hasMaxAvailableShort?: boolean;

  usdgAmount?: BigNumber;
  maxUsdgAmount?: BigNumber;

  poolAmount?: BigNumber;
  bufferAmount?: BigNumber;
  managedAmount?: BigNumber;
  managedUsd?: BigNumber;
  availableAmount?: BigNumber;
  availableUsd?: BigNumber;
  guaranteedUsd?: BigNumber;
  redemptionAmount?: BigNumber;
  reservedAmount?: BigNumber;

  balance?: BigNumber;

  weight?: BigNumber;

  maxPrice?: BigNumber;
  maxPrimaryPrice?: BigNumber;

  minPrice?: BigNumber;
  minPrimaryPrice?: BigNumber;

  contractMaxPrice?: BigNumber;
  contractMinPrice?: BigNumber;

  spread?: BigNumber;

  cumulativeFundingRate?: BigNumber;
  fundingRate?: BigNumber;

  globalShortSize?: BigNumber;

  maxAvailableLong?: BigNumber;
  maxAvailableShort?: BigNumber;

  maxGlobalLongSize?: BigNumber;
  maxGlobalShortSize?: BigNumber;

  maxLongCapacity?: BigNumber;
};

export type CancelOrder = {
  content: string;
  hash: string;
  isOpen: boolean;
};

export type IpriceK = {
  t: number;
  c: number;
  l: number;
  o: number;
  h: number;
};

export type IKlineData = {
  prices: [IpriceK];
};

export type IKlineBidsAndAsks = {
  bids: Array<Array<string>>;
  asks: Array<Array<string>>;
};

export type IDealHistory = {
  id: number;
  isBuyerMaker: boolean;
  time: number;
  price: string;
  qty: string;
};

export interface IAprItem {
  APR: string;
  btcAPR: string;
  ethAPR: string;
  usdtAPR: string;
}

export interface IListData {
  tokens: string;
  amount: string;
  liquidity: string;
  reward: string;
  apr: string;
}
