import { create, StoreApi, UseBoundStore } from 'zustand';

interface IAppStore {
  settingSlippageIsOpen: boolean;
  setSettingSlippageIsOpen: (settingSlippageIsOpen: boolean) => void;
}

export const useAppStore: UseBoundStore<StoreApi<IAppStore>> = create((set) => ({
  settingSlippageIsOpen: false,
  setSettingSlippageIsOpen: (settingSlippageIsOpen: boolean) => set({ settingSlippageIsOpen }),
}));
