import BigNumber from 'bignumber.js';
import useSWR from 'swr';

import { formatCompactNumber, formatDecimalPlaces, fromBigNumberPrice } from '@/lib/format';

import { getOpenInterest } from '@/api';
import { DashboardUrl } from '@/api/url';

import { OpenInterest } from '@/types';

export const useOpenInterest = () => {
  const { data }: { data?: OpenInterest } = useSWR(
    DashboardUrl.Position,
    async () => getOpenInterest(),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (retryCount >= 2) return;
        setTimeout(() => revalidate({ retryCount: retryCount }), 10000);
      },
    },
  );

  const result = formatDecimalPlaces(
    fromBigNumberPrice(
      new BigNumber(data?.longOpenInterest ?? '0').plus(data?.shortOpenInterest ?? '0'),
    ),
  );
  return {
    openInterest: formatCompactNumber(result),
  };
};
