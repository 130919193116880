import { create, StoreApi, UseBoundStore } from 'zustand';

import { IAprItem } from '@/types';

interface IApr {
  apr: IAprItem;
  setApr: (apr?: IAprItem) => void;
}

export const useAprStore: UseBoundStore<StoreApi<IApr>> = create((set) => ({
  apr: {
    APR: '0.00',
    ethAPR: '0.00',
    btcAPR: '0.00',
    usdtAPR: '0.00',
  },
  setApr: (apr?: IAprItem) => set({ apr }),
}));
