import useSWR from 'swr';
import { useAccount } from 'wagmi';

import { getEarnInfo } from '@/api';
import { PoolUrl } from '@/api/url';

export const useEarnInfo = () => {
  const { address } = useAccount();
  const { data } = useSWR(
    address ? `${PoolUrl.EarnInfo}-${address}` : null,
    async () => getEarnInfo(address!),
    {
      refreshInterval: 5000,
    },
  );
  return {
    earnInfo: data || {},
  };
};
