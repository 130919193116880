import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

import { IDealHistory, IKlineBidsAndAsks } from '@/types';

/** Merge classes with tailwind-merge with clsx full feature */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
export const formatKlineData = (data: any[]) => {
  if (!data) return [];
  return data?.map((p) => {
    return {
      timestamp: p[0],
      open: p[1],
      high: p[2],
      low: p[3],
      close: p[4],
      volume: p[5],
    };
  });
};
export const formatBidsAndAsks = (data: IKlineBidsAndAsks) => {
  if (!data) return [];
  const len = data.bids.length > 20 ? 20 : data.bids.length;
  const bids = data.bids.slice(0, len);
  const asks = data.asks.slice(0, len);
  const tmp = [];
  for (let i = 0; i < len; i++) {
    tmp.push([bids[i]?.[1] ?? [], bids[i]?.[0] ?? [], asks[i]?.[0] ?? [], asks[i]?.[1] ?? []]);
  }
  return tmp;
};

export const formatDealHistory = (data: [IDealHistory]) => {
  if (!data) return [];
  let res = [];
  res = data.map((d) => {
    const date = new Date(d.time);
    const h = date.getHours().toString().padStart(2, '0');
    const m = date.getMinutes().toString().padStart(2, '0');
    const s = date.getSeconds().toString().padStart(2, '0');
    // const tmp = JSON.parse(d.data.params);
    // console.warn(tmp);
    return {
      time: `${h}:${m}:${s}`,
      direction: d.isBuyerMaker ? 'buy' : 'sell',
      price: d.price,
      amount: d.qty,
    };
  });
  return res;
};

export const appBackEvent = (type: string) => {
  if (window.TECO_postMessage) {
    window.TECO_postMessage({
      type: type, //事件
      name: '',
    });
  }
};
