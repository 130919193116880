import useSWR from 'swr';

import { formatCompactNumber } from '@/lib/format';

import { getFeeTotal } from '@/api';
import { DashboardUrl } from '@/api/url';

export const useFeeTotal = () => {
  const { data } = useSWR(DashboardUrl.Total, async () => getFeeTotal(), {
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      if (retryCount >= 2) return;
      setTimeout(() => revalidate({ retryCount: retryCount }), 10000);
    },
  });
  return {
    total: formatCompactNumber(data) || 0,
  };
};
