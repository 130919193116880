export enum DashboardUrl {
  VolumeTotal = '/api/volume/total',
  Total = '/api/fee/total',
  Position = '/api/position',
}

export enum PoolUrl {
  RewardProof = '/reward-proof',
  EarnInfo = '/earn-info',
  StakingApy = '/staking-apy',
}

export enum OrderUrl {
  LiquidationPrice = '/liquidation-price',
  Entrust = '/orders_indices',
  Order = '/order',
  History = '/trade-data',
  Trade = '/trades',
  PositionTriggers = '/position-triggers',
}

export enum DealCenterUrl {
  Price = '/ticker',
  KLine = '/klines',
}

export enum InviteUrl {
  TestLogin = '/referrals/user/testLogin',
  UserInfo = '/referrals/user/info',
  Detail = '/referrals/user/detail',
  UserList = '/referrals/user/sub',
}
