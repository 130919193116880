import { Address } from 'wagmi';

import { formatDecimalPlaces, fromBigNumberPrice } from '@/lib/format';
import { getStorage } from '@/lib/storage';

import { DashboardUrl, DealCenterUrl, InviteUrl, OrderUrl, PoolUrl } from '@/api/url';

import axios from './axios';

import { OpenInterest } from '@/types';

export const getVolumeTotal = (): Promise<string> =>
  axios.get(DashboardUrl.VolumeTotal).then((res) => {
    if (res.data) {
      return formatDecimalPlaces(fromBigNumberPrice(res.data.total));
    }
    return '0';
  });

export const getKlineDataFromCenter = (symbol: string, interval: string, limit = 1) =>
  axios
    .get(`${DealCenterUrl.KLine}?symbol=${symbol}&interval=${interval}&limit=${limit}`, {
      baseURL: process.env.NEXT_PUBLIC_DEAL_CENTER_API_URL,
    })
    .then((res) => res.data);

export const getEntrustOrderList = (account: Address) =>
  axios.get(`${OrderUrl.Entrust}?account=${account}`).then((res) => res.data);

export const getOpenInterest = (): Promise<OpenInterest> =>
  axios.get(DashboardUrl.Position).then((res) => res.data);

export const getFeeTotal = (): Promise<string> =>
  axios.get(DashboardUrl.Total).then((res) => {
    if (res.data) {
      return formatDecimalPlaces(fromBigNumberPrice(res.data.total));
    }
    return '0';
  });

export const getHistoryOrderListNew = (account: Address) =>
  axios.get(`${OrderUrl.History}?account=${account}`).then((res) => res.data);

export const getRewardProof = (account: Address) =>
  axios.get(`${PoolUrl.RewardProof}?account=${account}`).then((res) => res.data.data);

export const getBidsAndAsks = (symbol: string, depth = 0.01) =>
  axios.get(`${OrderUrl.Order}?quote=${symbol}&depth=${depth}`).then((res) => res.data);

export const getOneDayDealInfo = (symbol: string) =>
  axios
    .get(`${DealCenterUrl.Price}?symbol=${symbol}`, {
      baseURL: process.env.NEXT_PUBLIC_DEAL_CENTER_API_URL,
    })
    .then((res) => res.data);

export const getEarnInfo = (account: Address) =>
  axios.get(`${PoolUrl.EarnInfo}?account=${account}`).then((res) => res.data.data);
export const getDealHistory = (symbol: string) =>
  axios.get(`${OrderUrl.Trade}?quote=${symbol}`).then((res) => res.data);
export const getPositionTriggers = (account: Address) =>
  axios.get(`${OrderUrl.PositionTriggers}?account=${account}`).then((res) => res.data);

export const getStakingApy = () => axios.get(PoolUrl.StakingApy).then((res) => res.data);

export const getLiquidationPrice = (
  isLong: boolean,
  size: string,
  collateral: string,
  entryPrice: string,
) =>
  axios
    .get(
      `${OrderUrl.LiquidationPrice}?isLong=${isLong}&size=${size}&collateral=${collateral}&entryPrice=${entryPrice}`,
    )
    .then((res) => res.data?.price);

export const postTestLogin = (account: Address | undefined) =>
  axios
    .post(
      InviteUrl.TestLogin,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: getStorage('authorization'),
          address: account,
        },
      },
    )
    .then((res) => res.data);

export const postInviteUserInfo = (account: Address | undefined) =>
  axios
    .post(
      InviteUrl.UserInfo,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: getStorage('authorization'),
          address: account,
        },
      },
    )
    .then((res) => res.data);

export const postInviteDetail = (account: Address | undefined) =>
  axios
    .post(
      InviteUrl.Detail,
      {
        pageNum: 1,
        pageSize: 100,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: getStorage('authorization'),
          address: account,
        },
      },
    )
    .then((res) => res.data);

export const postInviteUserList = (account: Address | undefined) =>
  axios
    .post(
      InviteUrl.UserList,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: getStorage('authorization'),
          address: account,
        },
      },
    )
    .then((res) => res.data);
