import useSWR from 'swr';
import { useAccount } from 'wagmi';

import { getPositionTriggers } from '@/api';
import { OrderUrl } from '@/api/url';

export const usePositionTriggers = () => {
  const { address } = useAccount();
  const { data }: { data: any[] } = useSWR(
    address ? `${OrderUrl.PositionTriggers}-${address}` : null,
    async () => getPositionTriggers(address!),
    {
      refreshInterval: 5000,
    },
  );

  return {
    list: data || [],
  };
};
