import useSWR from 'swr';
import { useAccount } from 'wagmi';

import { getRewardProof } from '@/api';
import { PoolUrl } from '@/api/url';

export const useRewardProof = () => {
  const { address } = useAccount();
  const { data } = useSWR(
    address ? `${PoolUrl.RewardProof}-${address}` : null,
    async () => getRewardProof(address!),
    {
      refreshInterval: 5000,
    },
  );
  return {
    rewardProof: data,
  };
};
