import { create, StoreApi, UseBoundStore } from 'zustand';

import { InfoToken } from '@/types';

interface IInfoTokensStore {
  infoTokens: InfoToken[];
  setInfoTokens: (infoTokens: InfoToken[]) => void;
}

export const useInfoTokensStore: UseBoundStore<StoreApi<IInfoTokensStore>> = create((set) => ({
  infoTokens: [],
  setInfoTokens: (infoTokens: InfoToken[]) => set({ infoTokens }),
}));
