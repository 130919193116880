import { create, StoreApi, UseBoundStore } from 'zustand';

import { IAllowance } from '@/types';

interface IAllowanceStore {
  allowance?: IAllowance;
  setAllowance: (allowance?: IAllowance) => void;
}

export const useAllowanceStore: UseBoundStore<StoreApi<IAllowanceStore>> = create((set) => ({
  allowance: undefined,
  setAllowance: (allowance?: IAllowance) => set({ allowance }),
}));
