import { create, StoreApi, UseBoundStore } from 'zustand';

import { TRADE_TOKENS } from '@/consts';

import { OrderType, TradeTokenKey, TradeTokenType, TradeType } from '@/types';

interface ITradeStore {
  tradeType: TradeType;
  orderType: OrderType;
  tradeToken: TradeTokenKey;
  tradeTokenType: TradeTokenType;
  leverageMultiple: number;
  checkedStopLimit: boolean;
  setTradeType: (tradeType: TradeType) => void;
  setOrderType: (orderType: OrderType) => void;
  setTradeToken: (tradeToken: TradeTokenKey) => void;
  setTradeTokenType: (TradeTokenType: TradeTokenType) => void;
  setLeverageMultiple: (leverageMultiple: number) => void;
  setCheckedStopLimit: (checkedStopLimit: boolean) => void;
}

export const useTradeStore: UseBoundStore<StoreApi<ITradeStore>> = create((set) => ({
  tradeType: TradeType.Long,
  orderType: OrderType.Limit,
  tradeToken: Object.keys(TRADE_TOKENS)[0] as TradeTokenKey,
  tradeTokenType: TradeTokenType.U,
  leverageMultiple: 5,
  checkedStopLimit: false,
  setTradeType: (tradeType: TradeType) => set({ tradeType }),
  setOrderType: (orderType: OrderType) => set({ orderType }),
  setTradeToken: (tradeToken: TradeTokenKey) => set({ tradeToken }),
  setTradeTokenType: (tradeTokenType: TradeTokenType) => set({ tradeTokenType }),
  setLeverageMultiple: (leverageMultiple: number) => set({ leverageMultiple }),
  setCheckedStopLimit: (checkedStopLimit: boolean) => set({ checkedStopLimit }),
}));
