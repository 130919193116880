import { create, StoreApi, UseBoundStore } from 'zustand';

import { IVaultAmounts } from '@/types';

type IFeeData = {
  gasPrice: bigint | null;
};

interface IFeeStore {
  fundingRates?: IVaultAmounts;
  feeData?: IFeeData;
  limitOrderFee: number;
  marketOrderFee: number;
  setFundingRates: (fundingRates: IVaultAmounts) => void;
  setFeeData: (feeData?: IFeeData) => void;
  setLimitOrderFee: (limitOrderFee: number) => void;
  setMarketOrderFee: (marketOrderFee: number) => void;
}

export const useFeeStore: UseBoundStore<StoreApi<IFeeStore>> = create((set) => ({
  fundingRates: undefined,
  feeData: undefined,
  limitOrderFee: 500000,
  marketOrderFee: 4000,
  setFundingRates: (fundingRates: IVaultAmounts) => set({ fundingRates }),
  setFeeData: (feeData?: IFeeData) => set({ feeData }),
  setLimitOrderFee: (limitOrderFee: number) => set({ limitOrderFee }),
  setMarketOrderFee: (marketOrderFee: number) => set({ marketOrderFee }),
}));
