import useSWR from 'swr';

import { getOneDayDealInfo } from '@/api';
import { DealCenterUrl } from '@/api/url';
export const useDealCenterInfo = (symbol: string) => {
  const { data } = useSWR(
    `${DealCenterUrl.Price}-${symbol}`,
    async () => getOneDayDealInfo(symbol),
    {
      refreshInterval: 10000,
    },
  );

  return {
    oneDay: data,
  };
};
