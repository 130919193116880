import useSWR from 'swr';

import { formatKlineData } from '@/lib/utils';

import { getKlineDataFromCenter } from '@/api';
import { DealCenterUrl } from '@/api/url';

import { KLineTimeKey } from '@/types';

export const useKlineData = (symbol: string, period: KLineTimeKey, limit = 1) => {
  const { data } = useSWR(
    `${DealCenterUrl.KLine}-${symbol}-${period}-${limit}`,
    async () => getKlineDataFromCenter(symbol, period, limit),
    {
      refreshInterval: 5000,
    },
  );
  return {
    data: formatKlineData(data),
  };
};
