export const getStorage = (key: string) => {
  if (typeof window !== 'undefined' && localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key) ?? '');
  }
};

export const setStorage = (key: string, value: any) => {
  if (typeof window !== 'undefined' && value) {
    return localStorage.setItem(key, JSON.stringify(value));
  }
};

export const removeStorage = (key: string) => {
  if (typeof window !== 'undefined' && localStorage.getItem(key)) {
    localStorage.removeItem(key);
  }
};
