import useSWR from 'swr';

import { usePricesStore } from '@/stores';

import { getOneDayDealInfo } from '@/api';
import { DealCenterUrl } from '@/api/url';
import { TRADE_TOKENS } from '@/consts';

import { TradeTokenKey } from '@/types';
import { CexPrice, ICexPrice } from '@/types/api';

export const useCexPrice = () => {
  const { setCexPrices } = usePricesStore();
  const arr = [];
  for (const symbol of Object.values(TRADE_TOKENS)) {
    arr.push(getOneDayDealInfo(`${symbol}USDT`));
  }
  const request = Promise.all(arr);

  useSWR(DealCenterUrl.Price, async () => request, {
    refreshInterval: 10000,
    onSuccess: (data: CexPrice[]) => {
      const result = {} as ICexPrice;
      if (data) {
        Object.keys(TRADE_TOKENS).forEach((address, index) => {
          result[address as TradeTokenKey] = {
            lastPrice: data[index].lastPrice,
            priceChangePercent: data[index].priceChangePercent,
          };
        });
      }
      if (Object.keys(result).length) {
        setCexPrices(result);
      }
    },
  });
};
