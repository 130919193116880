import useSWR from 'swr';
import { useAccount } from 'wagmi';

import { postInviteDetail, postInviteUserInfo, postInviteUserList } from '@/api';
import { InviteUrl } from '@/api/url';

export const useInviteUserInfo = () => {
  const { address } = useAccount();

  const { data } = useSWR(`${InviteUrl.UserInfo}`, async () => postInviteUserInfo(address), {
    refreshInterval: 10000,
  });

  return {
    data,
  };
};

export const useInviteDetail = () => {
  const { address } = useAccount();

  const { data } = useSWR(`${InviteUrl.Detail}`, async () => postInviteDetail(address), {
    refreshInterval: 10000,
  });

  return {
    data,
  };
};

export const useInviteUserList = () => {
  const { address } = useAccount();

  const { data } = useSWR(`${InviteUrl.UserList}`, async () => postInviteUserList(address), {
    refreshInterval: 10000,
  });

  return {
    data,
  };
};
